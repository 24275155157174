/* eslint-disable @typescript-eslint/no-explicit-any */
import { Divider, Grid, Paper, Typography } from '@mui/material';
import moment from 'moment';
import { RequestStatusType } from '../../types/types';

type PropType = {
  data: RequestStatusType;
};

function DisplayRequestCard({ data }: PropType) {
  return (
    <Grid container marginTop={2} marginBottom={2} justifyContent="center">
      <Paper sx={{ p: 2 }}>
        <Grid item sx={{ maxWidth: '100%', width: 320, mb: 2 }}>
          <Typography
            variant="body1"
            component="p"
            sx={{ pl: 2, pr: 2, pb: 1 }}
          >
            Lufthavn:
          </Typography>
          <Paper variant="outlined" sx={{ backgroundColor: '#EAEEF3' }}>
            <Typography variant="body1" component="p" sx={{ p: 2 }}>
              {data.airport}
            </Typography>
          </Paper>
        </Grid>
        <Divider sx={{ mb: 2, mt: 4 }} />
        <Grid item sx={{ maxWidth: '100%', width: 320, mb: 2 }}>
          <Typography
            variant="body1"
            component="p"
            sx={{ pl: 2, pr: 2, pb: 1 }}
          >
            Navn:
          </Typography>
          <Paper variant="outlined" sx={{ backgroundColor: '#EAEEF3' }}>
            <Typography variant="body1" component="p" sx={{ p: 2 }}>
              {data.request.name}
            </Typography>
          </Paper>
        </Grid>
        <Grid item sx={{ maxWidth: '100%', width: 320, mb: 2 }}>
          <Typography
            variant="body1"
            component="p"
            sx={{ pl: 2, pr: 2, pb: 1 }}
          >
            E-post:
          </Typography>
          <Paper variant="outlined" sx={{ backgroundColor: '#EAEEF3' }}>
            <Typography variant="body1" component="p" sx={{ p: 2 }}>
              {data.request.email}
            </Typography>
          </Paper>
        </Grid>
        <Grid item sx={{ maxWidth: '100%', width: 320, mb: 2 }}>
          <Typography
            variant="body1"
            component="p"
            sx={{ pl: 2, pr: 2, pb: 1 }}
          >
            Firma:
          </Typography>
          <Paper variant="outlined" sx={{ backgroundColor: '#EAEEF3' }}>
            <Typography variant="body1" component="p" sx={{ p: 2 }}>
              {data.request.company}
            </Typography>
          </Paper>
        </Grid>
        <Grid item sx={{ maxWidth: '100%', width: 320 }}>
          <Typography
            variant="body1"
            component="p"
            sx={{ pl: 2, pr: 2, pb: 1 }}
          >
            Forespørsel:
          </Typography>
          <Paper variant="outlined" sx={{ backgroundColor: '#EAEEF3' }}>
            <Typography variant="body1" component="p" sx={{ p: 2 }}>
              {data.request.description}
            </Typography>
          </Paper>
        </Grid>

        <Divider sx={{ mb: 2, mt: 4 }} />

        <Grid item sx={{ maxWidth: '100%', width: 320, mb: 2 }}>
          <Typography
            variant="body1"
            component="p"
            sx={{ pl: 2, pr: 2, pb: 1 }}
          >
            Fra:
          </Typography>
          <Paper variant="outlined" sx={{ backgroundColor: '#EAEEF3' }}>
            <Typography variant="body1" component="p" sx={{ p: 2 }}>
              {moment(data.periode.start).format('DD. MMM. YYYY, [kl.] HH:mm')}
            </Typography>
          </Paper>
        </Grid>
        <Grid item sx={{ maxWidth: '100%', width: 320 }}>
          <Typography
            variant="body1"
            component="p"
            sx={{ pl: 2, pr: 2, pb: 1 }}
          >
            Til:
          </Typography>
          <Paper variant="outlined" sx={{ backgroundColor: '#EAEEF3' }}>
            <Typography variant="body1" component="p" sx={{ p: 2 }}>
              {moment(data.periode.end).format('DD. MMM. YYYY, [kl.] HH:mm')}
            </Typography>
          </Paper>
        </Grid>

        <Divider sx={{ mb: 2, mt: 4 }} />

        <Grid item sx={{ maxWidth: '100%', width: 320, mb: 2 }}>
          <Typography
            variant="body1"
            component="p"
            sx={{ pl: 2, pr: 2, pb: 1 }}
          >
            Kjøretøy
          </Typography>
          <Paper variant="outlined" sx={{ backgroundColor: '#EAEEF3' }}>
            <Typography variant="body1" component="p" sx={{ p: 2 }}>
              {data.request.licencePlate}
            </Typography>
          </Paper>
        </Grid>
      </Paper>
    </Grid>
  );
}

export default DisplayRequestCard;
