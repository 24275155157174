/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alert, CircularProgress, Grid } from '@mui/material';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DisplayRequestCard from '../../../components/DisplayRequestCard';
import { RequestStatusType } from '../../../types/types';
import api from '../../../utils/api';

import './style.scss';

function StatusPage() {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [status, setStatus] = useState<'PENDING' | 'ACCEPTED' | 'DECLINED'>(
    'PENDING',
  );
  const [resultData, setResultData] = useState<RequestStatusType | undefined>(
    undefined,
  );

  const { id } = useParams();

  useEffect(() => {
    if (id) fetchRequestStatus(id);
  }, []);

  const fetchRequestStatus = async (id: string) => {
    const data = {
      id: id,
    };
    const result = await api<RequestStatusType, any>(
      `/request/status`,
      'POST',
      data,
    );
    if (!result) {
      setError(true);
    } else {
      if (result.error) {
        setError(true);
      }
      setLoading(false);
      setStatus(result.status);
      setResultData(result);
    }
  };

  const RenderPendingCard = (end: any) => {
    const end_time = moment(end.end).unix();
    const current_time = moment().unix();
    if (end_time < current_time) {
      return (
        <Alert severity="error">
          Det er ikke mulig å godkjenne forespørsler om parkeringstilatelse når
          sluttidspunktet er passert.
          <br />
          <br />
          <b>Parkeringsforespørselen er avvist.</b>
        </Alert>
      );
    } else {
      return (
        <Alert severity="warning">
          Parkeringsforespørselen er sendt til godkjenning.
          <br />
          <br />
          <b>Den er foreløpig ikke godkjent.</b>
        </Alert>
      );
    }
  };

  return (
    <Fragment>
      {loading ? (
        <Grid container marginTop={4} justifyContent="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : error ? (
        <Grid container marginTop={2} justifyContent="center">
          <Grid item sx={{ maxWidth: 320 }}>
            <Alert severity="error">
              Vi klarer ikke å laste inn parkeringsforespørselen.
            </Alert>
          </Grid>
        </Grid>
      ) : (
        <Grid container marginTop={2} justifyContent="center">
          <Grid item sx={{ maxWidth: 320 }}>
            {status === 'DECLINED' && (
              <Alert severity="error">Parkeringsforespørselen er avvist.</Alert>
            )}
            {status === 'PENDING' && resultData && (
              <RenderPendingCard end={resultData.periode.end} />
            )}
            {status === 'ACCEPTED' && (
              <Alert severity="success">
                Parkeringsforespørselen er godkjent.
              </Alert>
            )}
            {resultData && <DisplayRequestCard data={resultData} />}
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
}

export default StatusPage;
