/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//Types
import { AirportType } from '../../../types/types';

import api from '../../../utils/api';

//import api from '../../utils/api';

import './style.scss';

function getLastDateOfMonth() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // months are zero-based

  // Get the last day of the next month and subtract 1 to get the last day of the current month
  const lastDay = new Date(year, month, 0).getDate();

  // Format the date and time as a string
  const dateString = `${year}-${month.toString().padStart(2, '0')}-${lastDay
    .toString()
    .padStart(2, '0')}`;
  const timeString = '23:59';
  return `${dateString}T${timeString}`;
}

function getCurrentDate() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // months are zero-based
  const day = currentDate.getDate();

  // Format the date and time as a string
  const dateString = `${year}-${month.toString().padStart(2, '0')}-${day
    .toString()
    .padStart(2, '0')}`;
  const timeString = '00:00';
  return `${dateString}T${timeString}`;
}

function formatDate(dateString: string): string {
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  return date.toLocaleDateString('nb-NO', options);
}

function ApplyNewWhitelistingPage() {
  const [airports, setAirports] = useState<AirportType[]>([]);
  const [airportsLoading, setAirportsLoading] = useState<boolean>(true);
  const [selectedAirport, setSelectedAirport] = useState<string>('');

  const [selectedType, setSelectedType] = useState<string>('');

  const [staff, setStaff] = useState<any[]>([]);
  const [staffLoading, setStaffLoading] = useState<boolean>(true);
  const [selectedStaff, setSelectedStaff] = useState<string>('');

  const [contactName, setContactName] = useState<string>('');
  const [contactEmail, setContactEmail] = useState<string>('');
  const [contactCompany, setContactCompany] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [licencePlate, setLicencePlate] = useState<string>('');

  const [periodeStart, setPeriodeStart] = useState<string>('');
  const [periodeEnd, setPeriodeEnd] = useState<string>('');

  const [postLoading, setPostLoading] = useState<boolean>(false);
  const [postError, setPostError] = useState<boolean>(false);

  const navigate = useNavigate();

  //Load airports from server
  useEffect(() => {
    loadAirports();
  }, []);

  const loadAirports = async () => {
    const result = await api<any, any>(`/request/airports/get`, 'GET');
    if (!result) {
      setAirportsLoading(false);
      console.log('data');
    } else {
      setAirportsLoading(false);
      setAirports(result.airports);
    }
  };

  const handleChangeAirport = (event: SelectChangeEvent) => {
    setSelectedAirport(event.target.value);
    setSelectedStaff('');
    loadAirportStaff(event.target.value);
  };

  const loadAirportStaff = async (airportId: string) => {
    setStaffLoading(true);
    const data = { id: airportId };
    const result = await api<any, any>(
      `/request/airport/staff/get`,
      'POST',
      data,
    );
    if (!result) {
      setStaffLoading(false);
    } else {
      setStaffLoading(false);
      setStaff(result.staff);
    }
  };

  const handleChangeStaff = (event: SelectChangeEvent) => {
    setSelectedStaff(event.target.value);
  };

  const handleChangeType = (event: SelectChangeEvent) => {
    setSelectedType(event.target.value);
    if (event.target.value === '2') {
      const lastDate = getLastDateOfMonth();
      const currentDate = getCurrentDate();
      setPeriodeStart(currentDate);
      setPeriodeEnd(lastDate);
      setDescription('Ansattparkering i påvente av ID-kort.');
    }
  };

  const postRequestBtn = () => {
    setPostLoading(true);
    const data = {
      contactPerson: selectedStaff,
      airport: selectedAirport,
      request: {
        name: contactName,
        email: contactEmail,
        company: contactCompany,
        description: description,
        periode: {
          start: Date.parse(periodeStart),
          end: Date.parse(periodeEnd),
        },
        licencePlate: licencePlate,
      },
    };
    postNewRequest(data);
  };

  const postNewRequest = async (data: any) => {
    const result = await api<any, any>(`/request/new`, 'POST', data);
    if (!result) {
      setPostLoading(false);
      setPostError(true);
    } else {
      setPostLoading(false);

      if (result.status !== 'OK') {
        setPostError(true);
      }
      if (result.requestId) {
        navigate(`/request/${result.requestId}`);
      }
    }
  };

  const handleSetPeriodeStart = (value: any) => {
    setPeriodeStart(value);
  };

  return (
    <Fragment>
      {airportsLoading ? (
        <Grid container marginTop={2} justifyContent="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <Box sx={{ flexGrow: 1, height: '100%' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '26px',
            }}
          >
            <Typography variant="h5" component="p">
              Velg lufthavn:
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <FormControl sx={{ m: 1, minWidth: 320, maxWidth: 320 }}>
              <Alert severity="info" sx={{ mb: 2 }}>
                Velg den lufthavnen der du skal ha parkeringstillatelse.
              </Alert>
              <Select
                value={selectedAirport}
                displayEmpty
                placeholder={'Velg lufthavn'}
                onChange={handleChangeAirport}
                sx={{ backgroundColor: 'white' }}
              >
                <MenuItem value="">
                  <em>Velg lufthavn</em>
                </MenuItem>
                {airports.map(el => {
                  return (
                    <MenuItem key={el.id} value={el.id}>
                      {el.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </Box>
      )}

      {selectedAirport && (
        <Fragment>
          {staffLoading ? (
            <Grid container marginTop={2} justifyContent="center">
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            <Box sx={{ flexGrow: 1, height: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '26px',
                }}
              >
                <Typography variant="h5" component="p">
                  Velg type forespørsel:
                </Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <FormControl sx={{ m: 1, minWidth: 320, maxWidth: 320 }}>
                  <Alert severity="info" sx={{ mb: 2 }}>
                    Velg om du søker om <b>ordinær parkeringstillatelse</b>{' '}
                    eller om <b>ansattparkering</b> i påvente av ID-kort.
                  </Alert>
                  <Select
                    value={selectedType}
                    displayEmpty
                    placeholder={'Velg type'}
                    onChange={handleChangeType}
                    sx={{ backgroundColor: 'white' }}
                  >
                    <MenuItem value="">
                      <em>Velg type</em>
                    </MenuItem>
                    <MenuItem value={'1'}>
                      Ordinær parkeringstillatelse
                    </MenuItem>
                    <MenuItem value={'2'}>Ansattparkering</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Box>
          )}
        </Fragment>
      )}

      {selectedType && (
        <Fragment>
          {staffLoading ? (
            <Grid container marginTop={2} justifyContent="center">
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            <Box sx={{ flexGrow: 1, height: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '26px',
                }}
              >
                <Typography variant="h5" component="p">
                  Velg kontaktperson:
                </Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <FormControl sx={{ m: 1, minWidth: 320, maxWidth: 320 }}>
                  <Alert severity="info" sx={{ mb: 2 }}>
                    Velg kontaktpersonen på lufthavnen som skal godkjenne din
                    forespørsel.
                  </Alert>
                  <Select
                    value={selectedStaff}
                    displayEmpty
                    placeholder={'Velg kontaktperson'}
                    onChange={handleChangeStaff}
                    sx={{ backgroundColor: 'white' }}
                  >
                    <MenuItem value="">
                      <em>Velg kontaktperson</em>
                    </MenuItem>
                    {staff.map(el => {
                      return (
                        <MenuItem key={el.uid} value={el.uid}>
                          {el.name} - {el.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </Box>
          )}
        </Fragment>
      )}
      {selectedStaff && selectedType && (
        <Fragment>
          <Box sx={{ flexGrow: 1, height: '100%' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '26px',
              }}
            >
              <Typography variant="h5" component="p">
                Oppgi din kontaktinformasjon:
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <FormControl sx={{ m: 1, minWidth: 320, maxWidth: 320 }}>
                <Alert severity="info" sx={{ mb: 2 }}>
                  Fyll ut din kontaktinformasjon og en beskrivelse av bakgrunnen
                  for forespørselen.
                </Alert>
                <TextField
                  id="outlined-name-1"
                  label="Navn"
                  value={contactName}
                  onChange={e => {
                    setContactName(e.target.value);
                  }}
                  sx={{ mb: 2, backgroundColor: 'white' }}
                />
                <TextField
                  id="outlined-name"
                  label="E-post"
                  value={contactEmail}
                  onChange={e => {
                    setContactEmail(e.target.value);
                  }}
                  sx={{ mb: 2, backgroundColor: 'white' }}
                />
                <TextField
                  id="outlined-uncontrolled"
                  label="Firma"
                  value={contactCompany}
                  onChange={e => {
                    setContactCompany(e.target.value);
                  }}
                  sx={{ mb: 2, backgroundColor: 'white' }}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Beskrivelse"
                  value={description}
                  onChange={e => {
                    setDescription(e.target.value);
                  }}
                  sx={{ backgroundColor: 'white' }}
                  multiline
                  disabled={selectedType === '2'}
                  minRows={4}
                  maxRows={10}
                />
              </FormControl>
            </div>
          </Box>
          <Box sx={{ flexGrow: 1, height: '100%' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '26px',
              }}
            >
              <Typography variant="h5" component="p">
                Oppgi kjøretøy:
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <FormControl sx={{ m: 1, minWidth: 320, maxWidth: 320 }}>
                <Alert severity="info" sx={{ mb: 2 }}>
                  Oppgi registreringsnummeret til kjøretøyet som skal motta
                  parkeringstillatelse.
                </Alert>
                <TextField
                  id="license-plate"
                  label="Registreringsnummer"
                  value={licencePlate}
                  onChange={e => {
                    setLicencePlate(
                      e.target.value.replace(/[^a-z0-9]/gi, '').toUpperCase(),
                    );
                  }}
                  sx={{ mb: 2, backgroundColor: 'white' }}
                />
              </FormControl>
            </div>
          </Box>
          <Box sx={{ flexGrow: 1, height: '100%' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '26px',
              }}
            >
              <Typography variant="h5" component="p">
                Periode:
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <FormControl sx={{ m: 1, minWidth: 320, maxWidth: 320 }}>
                {selectedType === '1' ? (
                  <Alert severity="info" sx={{ mb: 2 }}>
                    Velg perioden som parkeringstillatelsen skal gjelde.
                  </Alert>
                ) : (
                  <Alert severity="info" sx={{ mb: 2 }}>
                    Søknad om ansattparkering gjelder til utgangen av
                    inneværende måned. Forlengelse må søkes om på nytt hver
                    måned frem til ID-kort er mottatt.
                    <br />
                    <br />
                    Denne søknadden vil gjelde fra den blir godkjent til{' '}
                    <b>{formatDate(periodeEnd)}</b>.
                  </Alert>
                )}
                {selectedType === '1' && (
                  <Fragment>
                    <TextField
                      id="datetime-local"
                      label="Start"
                      type="datetime-local"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ mb: 2, backgroundColor: 'white' }}
                      value={periodeStart}
                      onChange={e => {
                        handleSetPeriodeStart(e.target.value);
                      }}
                    />
                    <TextField
                      id="datetime-local"
                      label="Slutt"
                      type="datetime-local"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ mb: 2, backgroundColor: 'white' }}
                      value={periodeEnd}
                      onChange={e => {
                        setPeriodeEnd(e.target.value);
                      }}
                    />
                  </Fragment>
                )}
              </FormControl>
            </div>
          </Box>

          {postError && (
            <Grid container marginTop={2} justifyContent="center">
              <Grid item>
                <Paper
                  sx={{ p: 2, mb: 2, backgroundColor: 'red', maxWidth: 320 }}
                >
                  <Alert severity="error">
                    En feil har oppstått, og din forespørsel har ikke blitt
                    sendt inn. Prøv igjen.
                  </Alert>
                </Paper>
              </Grid>
            </Grid>
          )}

          {postLoading ? (
            <Grid container marginTop={2} justifyContent="center">
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            <Box sx={{ flexGrow: 1, height: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '26px',
                }}
              >
                <Button
                  disabled={
                    !contactName ||
                    !contactCompany ||
                    !contactEmail ||
                    !description ||
                    !licencePlate ||
                    !periodeStart ||
                    !periodeEnd
                  }
                  onClick={postRequestBtn}
                  variant="contained"
                  size="large"
                  sx={{
                    pl: 4,
                    pr: 4,
                    mb: 10,
                    backgroundColor: '#84216B',
                    '&:hover': {
                      backgroundColor: '#470A59',
                    },
                  }}
                >
                  Send inn forespørsel
                </Button>
              </div>
            </Box>
          )}
        </Fragment>
      )}
    </Fragment>
  );
}

export default ApplyNewWhitelistingPage;
