import { API_URL } from '../config';

// eslint-disable-next-line
export default async <T, B>(
  url: string,
  method: 'GET' | 'POST',
  body: B | undefined = undefined,
  headers = {},
): Promise<T | (B | { error: string })> => {
  const controller = new AbortController();
  try {
    const res = await fetch(`${API_URL}${url}`, {
      method: method,
      signal: controller.signal,
      body: typeof body === 'object' ? JSON.stringify(body) : undefined,
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
    });
    if (!res.ok) {
      const error = await res.json();
      return { error: error };
    }
    return await res.json();
  } catch (err) {
    return { error: 'Error' };
  } finally {
    controller.abort();
  }
};
