/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
} from '@mui/material';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DisplayRequestCard from '../../../components/DisplayRequestCard';
import { RequestStatusType } from '../../../types/types';
import api from '../../../utils/api';

import './style.scss';

function ApprovePage() {
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [status, setStatus] = useState<'PENDING' | 'ACCEPTED' | 'DECLINED'>(
    'PENDING',
  );
  const [resultData, setResultData] = useState<RequestStatusType | undefined>(
    undefined,
  );
  const [errorLoad, setErrorLoad] = useState<boolean>(false);

  const [updateStatusLoading, setUpdateStatusLoading] =
    useState<boolean>(false);

  const { id, aid } = useParams();

  useEffect(() => {
    if (id) fetchRequestStatus(id);
  }, []);

  const fetchRequestStatus = async (id: string) => {
    const data = {
      id: id,
    };
    const result = await api<RequestStatusType, any>(
      `/request/status`,
      'POST',
      data,
    );
    if (!result) {
      setLoading(false);
      setError(true);
    } else {
      setLoading(false);
      if (result.error) {
        setErrorLoad(true);
      }
      setStatus(result.status);
      setResultData(result);
    }
  };

  const updateRequest = async (status: 'ACCEPTED' | 'DECLINED') => {
    setUpdateStatusLoading(true);
    const data = {
      id: id,
      aid: aid,
      status: status,
    };
    const result = await api<any, any>(`/request/status/update`, 'POST', data);
    if (!result) {
      setUpdateStatusLoading(false);
    } else {
      if (id) fetchRequestStatus(id);
      setUpdateStatusLoading(false);
      window.scrollTo(0, 0);
      if (result.error) {
        setError(true);
      }
    }
  };

  const ApproveBtn = (end: any) => {
    const end_time = moment(end.end).unix();
    const current_time = moment().unix();
    if (end_time > current_time) {
      return (
        <Fragment>
          {updateStatusLoading ? (
            <Grid container marginTop={4} justifyContent="center">
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              marginTop={2}
              marginBottom={2}
              justifyContent="center"
            >
              <Paper sx={{ p: 2 }}>
                <Grid item sx={{ maxWidth: '100%', width: 320, pt: 2, pb: 2 }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      color="success"
                      size="large"
                      variant="contained"
                      sx={{ width: 230, mb: 0 }}
                      onClick={() => {
                        updateRequest('ACCEPTED');
                      }}
                    >
                      Aksepter
                    </Button>
                  </div>
                  <Divider sx={{ mt: 3 }} />
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: '26px',
                    }}
                  >
                    <Button
                      size="large"
                      variant="contained"
                      color="error"
                      sx={{ width: 230 }}
                      onClick={() => {
                        updateRequest('DECLINED');
                      }}
                    >
                      Avvis
                    </Button>
                  </div>
                </Grid>
              </Paper>
            </Grid>
          )}
        </Fragment>
      );
    } else {
      return null;
    }
  };

  const RenderPendingCard = (end: any) => {
    const end_time = moment(end.end).unix();
    const current_time = moment().unix();
    if (end_time < current_time) {
      return (
        <Alert severity="error">
          Det er ikke mulig å godkjenne forespørsler om parkeringstilatelse
          etter at sluttidspunktet for forespørselen er passert.
          <br />
          <br />
          <b>Parkeringsforespørselen er avvist.</b>
        </Alert>
      );
    } else {
      return (
        <Alert severity="warning">
          Parkeringsforespørselen er sendt til godkjenning.
          <br />
          <br />
          <b>Den er foreløpig ikke godkjent.</b>
        </Alert>
      );
    }
  };

  return (
    <Fragment>
      {loading ? (
        <Grid container marginTop={4} justifyContent="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : errorLoad ? (
        <Grid container marginTop={2} justifyContent="center">
          <Grid item sx={{ maxWidth: 320 }}>
            <Alert severity="error">
              Vi klarer ikke å laste inn parkeringsforespørselen.
            </Alert>
          </Grid>
        </Grid>
      ) : (
        <Grid container marginTop={2} justifyContent="center">
          <Grid item sx={{ maxWidth: 320 }}>
            {error && (
              <Paper sx={{ p: 2, mb: 2, backgroundColor: 'red' }}>
                <Alert severity="error">
                  En feil har oppstått, og kjøretøyet har ikke blitt
                  hvitelistet.
                </Alert>
              </Paper>
            )}
            {status === 'DECLINED' && (
              <Alert severity="error">Parkeringsforespørselen er avvist.</Alert>
            )}
            {status === 'PENDING' && resultData && (
              <RenderPendingCard end={resultData.periode.end} />
            )}
            {status === 'ACCEPTED' && (
              <Alert severity="success">
                Parkeringsforespørselen er godkjent.
              </Alert>
            )}
            {resultData && <DisplayRequestCard data={resultData} />}
            {status === 'PENDING' && resultData && (
              <ApproveBtn end={resultData.periode.end} />
            )}
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
}

export default ApprovePage;
